import React from "react";
import { Portlet, PortletBody } from "../app/partials/content/Portlet";
import { Modal } from "react-bootstrap";

export const EmptyComponent = () => (<></>);

export const PlaceholderComponent = ({children}) => (<>{children && children}</>);
 
export const DashboardSquare = ({number, description, chartWidget}) => <Portlet className="kt-portlet kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
    <PortletBody fluid={true}>
        <div className="kt-widget26">
            <div className="kt-widget26__content">
                <span className="kt-widget26__number">{number}</span>
                <span className="kt-widget26__desc">{description}</span>
            </div>
            <div className="kt-widget26__chart">
                {chartWidget}
                {/* <span className="kt-badge kt-badge--dark kt-badge--inline kt-badge--pill kt-badge--rounded kt-badge--page">DRAFT</span> */}
            </div>
        </div>
    </PortletBody>
</Portlet>

export const AutoGrid = ({children, col = 1}) => {
    if(col > 4) col = 4; 

    return <div className={`auto-grid auto-grid-x${col}`}>{children}</div>
}

export const AutoRowCol = ({children, col = "col-sm-12"}) => {
    return <div className="row">
        {
            children.map((child, index) => (<div className={col} key={`auto-row-col-${index}`}>{child}</div>))
        }
    </div>
};

export const Badge = ({message, theme="brand"}) => (<span className={`kt-badge kt-badge--${theme} kt-badge--inline kt-badge--pill kt-badge--rounded kt-badge--page`}>{message}</span>);

export const QuickAddBtn = ({message, onClick}) => <button className="btn btn-label-brand btn-bold btn-sm btn-icon-h kt-margin-l-10" onClick={onClick}>{message}</button>;

export const EmptyModal = ({ show = false, onHide, children }) => (<Modal show={show} onHide={onHide}>{children}</Modal>);

export const Spinner = ({sm = false}) => {
    let classList = ["spinner-grow"]; 
    if(sm) classList.push("spinner-grow-sm");

    return <span role="status" aria-hidden="true" className={classList.join(" ")}></span>
}