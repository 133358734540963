import React from "react";
import { Link } from "react-router-dom";

import API from "../../../components/API";
import { DashboardSquare, AutoRowCol, Badge, QuickAddBtn, EmptyModal} from "../../../components/Base";
import { Modal } from "react-bootstrap";
import QuickAddPage from "../../partials/forms/QuickAddPage";
import SweetAlert from "sweetalert2-react";

const defaultDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam a tortor nisi. Vestibulum malesuada felis eros, sed volutpat ipsum pharetra a. Nam maximus vitae purus at commodo. Integer sapien diam, sodales eu libero vel, lobortis ullamcorper felis. Donec dictum blandit ex at aliquam. Donec pulvinar nibh sed felis accumsan aliquam. Pellentesque pulvinar feugiat imperdiet. Aenean mollis, risus id sagittis fringilla, ex arcu porttitor tortor, ac rutrum turpis lacus nec lacus. Duis et quam semper, molestie ante sodales, mattis erat. Curabitur tellus ante, aliquam eget sem ut, tempus euismod felis. In sem eros, posuere sed enim vel, egestas maximus enim. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Duis sollicitudin mi neque. Etiam ac ex purus. Ut ac mollis sapien, at ultrices libero.";
class PageComponent extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            // variables
            data: [],
            loading: false,
            
            // components
            showAddNewItemModal: false,
            showAddNewSuccessfulSwal: false,
            showSomethingWentWrongSwal: false
        };

        this._mounted = false;

        // functions
        this.getData = this.getData.bind(this);

        this.handleQuickAddBtnClick = this.handleQuickAddBtnClick.bind(this);
        this.handleQuickAddModalSubmit = this.handleQuickAddModalSubmit.bind(this);
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async componentDidMount () {
        this._isMounted = true;

        const { title } = this.state;
        this._isMounted && this.setState({subheader : {title : title}})
        this.getData();
    }

    async getData () {
        this.setState({loading : true});
        API.Get(`/pages`, (err, data) => {
            if(err) console.log(err);
            else {
                this.setState({
                    data : data.data,
                    loading: false
                });
            }
        });
    }

    /** UI Functions */

    handleQuickAddBtnClick = (event) => {
        // console.log('click add new');
        this.setState({
            "showAddNewItemModal" : true
        });
    }

    handleQuickAddModalClose = () => {
        this.setState({ showAddNewItemModal : false});
    }

    handleQuickAddModalSubmit = (values, setSubmitting) => {
        values.created_by = "5db827f341fce8435c3ae18a";
        API.Post({
            urlPath: `/pages`,
            reqBody: values
        }, (err, data) => {
            setSubmitting(false);

            if(err) {
                this.setState({ showSomethingWentWrongSwal : true});
                console.log(err);
            }
            else {
                this.setState({showAddNewSuccessfulSwal: true, showAddNewItemModal: false});
                this.getData();
            }
        });
    }

    render() {
        const { Layout } = this.props, { data, showAddNewItemModal, showAddNewSuccessfulSwal, showSomethingWentWrongSwal, loading} = this.state;

        const subheader = {
            "title" : "List of Pages",
            "showSeparator" : true, 
            "showLeftMenu" : true,
            "LeftMenuComponent" : <QuickAddBtn message="Add New" onClick={this.handleQuickAddBtnClick}/>
        }

        return <Layout title = "Pages" subheaderOptions={subheader}>
            {loading ? <p> Loading </p> : <DataSection data={data}/>}
            <QuickAddFormModal show={showAddNewItemModal} onHide={this.handleQuickAddModalClose} onSubmit={this.handleQuickAddModalSubmit}/>
            <SweetAlert show={showAddNewSuccessfulSwal} text={"Page Added!"} title={"Success!"} type="success"/>
            <SweetAlert show={showSomethingWentWrongSwal} text={"Something went wrong!"} title={"Oops!"} type="error"/>
        </Layout>
    }
}

const DataSection = ({data}) => (
    <AutoRowCol col={`col-sm-3`}>
        {
            data.map(({slug, title, status, description = defaultDescription, type}, index) => 
                <Link to={`/pages/${type}/${slug}`} className="kt-link kt-link--brand" key={`pages-${index}`}>
                    <DashboardSquare
                        number={title}
                        description={description.substr(0, 150) + "..."}
                        chartWidget= {<Badge message={status} theme={status === "published" ? "success" : "dark"}/>}/>
                </Link>
            )
        }
    </AutoRowCol>
);

const QuickAddFormModal = ({show = true, onHide, onSubmit}) => (
    <EmptyModal show={show} onHide={onHide}>
        <Modal.Header closeButton><Modal.Title>Add New Page</Modal.Title></Modal.Header>
        <QuickAddPage onHide={onHide} onSubmit={onSubmit}/>
    </EmptyModal>
);


const Page = ({Layout}) => {
    return <PageComponent Layout={Layout}/>
}   

export default Page;
