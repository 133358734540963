import React from "react";
import SweetAlert from "sweetalert2-react";

import { useParams } from "react-router-dom";

import API from "../../../components/API";
import TabbedPortlet from "../../../components/TabbledPortlet";
import ProSourceForm from "../../../components/ProSourceForm";
import { FormInput } from "../../../components/Form";
import DetailsTab from "../../partials/content/RootPage/DetailsTab";

class PageComponent extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            data : {},
            dataLoaded : false, 
            loading : false,
            showSomethingWentWrongSwal : false
        };

        this._isMounted = false;

        this.getData = this.getData.bind(this);
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async componentDidMount () {
        this._isMounted = true;
        this.getData();
    }

    async getData () {
        const { slug } = this.props;

        this.setState({loading : true})
        API.Get(`/pages/root/${slug}`, (err, data) => {
            if(err) {
                console.log(err);
                this.setState({
                    showSomethingWentWrongSwal : true
                })
            }
            else {
                this._isMounted && this.setState({
                    data : data.data,
                    loading : false,
                    dataLoaded : true
                });
            }
        });
    }

    render () {
        const { Layout, slug } = this.props, { data, dataLoaded, showSomethingWentWrongSwal, loading }= this.state;

        let subheader = {
            "showSeparator" : true
        };

        const title = subheader.title =  dataLoaded ? data.title : slug;

        return <Layout subheaderOptions={subheader} title={`Page: ${title}`}>
            { loading ? <p> Loading </p> : <DataDiv slug={slug} data={data}/> }
            <SweetAlert show={showSomethingWentWrongSwal} text={"Something went wrong!"} title={"Oops!"} type="error"/>
        </Layout>
    }
}

class DataDiv extends React.Component {
    render() {
        const { data } = this.props;
        return <TabbedPortlet active={0} contents={[
            {
                "href" : "#details",
                "label" : <> <i className="la la-align-justify"></i> Details </>,
                "component" : <DetailsTab data={data}/>
            },
            {
                "href" : "#appearance",
                "label" : <> <i className="la la-code"></i> Appearance </>,
                "component" : <p> 2 </p>
            },
            {
                "href" : "#activity-log",
                "label" : <> <i className="la la-bell"></i> Activity Log </>,
                "component" : <p> 3 </p>
            },
        ]}/>
    }
}

const Page = ({Layout}) => {
    const { slug } = useParams();
    return <PageComponent Layout={Layout} slug={slug}/>
}   

export default Page;