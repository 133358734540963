import React from "react";

class PortletHead extends React.Component {
    render () {
        const { children, title} = this.props;

        const TitleOnly = ({title}) => (<div className="kt-portlet__head-label"> <h3 class="kt-portlet__head-title"> {title} </h3> </div>)

        return <div className="kt-portlet__head">
            { title ? <TitleOnly title={title}/> : children}
        </div>
    }
}

class PortletBody extends React.Component {
    render () {
        const { children } = this.props;
        return <div className="kt-portlet__body">
            { children }
        </div>
    }
}

class Portlet extends React.Component {
    render () {
        let { classList = [] } = this.props;

        if(classList.indexOf("kt-portlet") === -1) classList.push("kt-portlet");

        let PortletClassList = classList.join(" ");

        return <div className={PortletClassList}>
            {this.props.children}
        </div>
    }
}

Portlet.Head = PortletHead;
Portlet.Body = PortletBody;

export default Portlet;