import React from "react";
import BasicPortlet from "./BasicPortlet"

class TabbedPortlet extends BasicPortlet {
    constructor (props) {
        super (props);

        const { active = 0, contents = []} = this.props;

        this.state = {
            active: active, 
            contents: contents
        };

        this.onClick = this.onClick.bind(this);
    }

    onClick = (event) => {
        const { contents } = this.state, targetHref = event.currentTarget.getAttribute("href");

        const nextActiveIndex = contents.findIndex(({href}) => {
            return href === targetHref;
        });

        this.setState({
            active: nextActiveIndex
        });
    }

    render () {
        const TabContentItem = ({active = false, children}) => (<div className={`tab-pane${active ? " active" : ""}`} role="tabpanel">{children}</div>)
        const TabContentDiv = ({contents, active}) => {
            return <div className="tab-content"> 
                {
                    contents.map(({ component }, index) => {
                        return <TabContentItem active={(active === index)} key={`tc-${index}`}>
                            {component}
                        </TabContentItem>
                    })
                }
            </div>
        }

        const NavItem = ({ active = false, children, onClick, href}) => ( <li className="nav-item" ><a onClick={onClick} className={`nav-link${active ? " active" : ""}`} data-toggle="tab" href={href} role="tab">{children}</a></li>)

        const NavTabsDiv = ({ contents, active, onClick}) => {
            return <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-brand" role="tablist">
                {
                    contents.map(({ label, href}, index) => {
                        return <NavItem active={(active === index)} key={`tc-${index}`} onClick={onClick} href={href}>
                            {label}
                        </NavItem>
                    })
                }
            </ul>
        }

        const { active, contents} = this. state;

        return <BasicPortlet classList={["kt-portlet", "kt-portlet--tabs"]}>
            <BasicPortlet.Head>
                <div className="kt-portlet__head-toolbar">
                    <NavTabsDiv contents={contents} active={active} onClick={this.onClick}/>
                </div>
            </BasicPortlet.Head>
            <BasicPortlet.Body>
                <TabContentDiv contents={contents} active={active}/>
            </BasicPortlet.Body>
        </BasicPortlet>
    }
}

export default TabbedPortlet;