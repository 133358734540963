import React from "react";

/**
 * Form handler in React (for form builders, see `components/Form.js`)
 * by: Jessiree de Vera | ProSource Dev Team (c) 2019
 * 
 * Basically mimicks Formik, but by using `extends` instead of being in a `render()` component.
 * This handles the form change out of the box, but to make this form useful, validation wise
 * you can overwrite `validateForm` in your form class or add validation rules in `this.validate`
*/

export default class ProSourceForm extends React.Component {
    constructor(props) {
        super (props);

        this.state = {
            values : {},
            errors : {},
            touched: {},
            isSubmitting: false
        };

        this.validate = {};

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);

        this.handleFormValidation = this.handleFormValidation.bind(this);

        this.setSubmitting = this.setSubmitting.bind(this);
        this.setStateKey = this.setStateKey.bind(this);
        this.setFieldValue = this.setFieldValue.bind(this);
        this.setAllFieldsToTouched = this.setAllFieldsToTouched.bind(this);

        this.validateForm = this.validateForm.bind(this);

        this.getError = this.getError.bind(this);
        this.getFields = this.getFields.bind(this);
        this.getStateKeyValue = this.getStateKeyValue.bind(this);

        this.isFormOk = this.isFormOk.bind(this);
        this.isTouched = this.isTouched.bind(this);
    }

    async handleOnSubmit(event){
        event.preventDefault();

        await this.setAllFieldsToTouched();

        if(this.isFormOk()){
            this.setSubmitting();
            this.onSubmit && this.onSubmit(this.state.values, this.setSubmitting);
        }
    }

    handleOnChange = event => {
        const target = event.target, value = target.value, name = target.name;

        let { values } = this.state; 

        values[name] = value;

        this.setState({
            values: values
        });

        this.validateForm();

        // checks if afterOnChange function exists
        this.onChange && this.onChange(event);
    }

    handleOnBlur = event => {
        const target = event.target, name = target.name;
        this.setStateKey("touched", name, "true");
        this.validateForm();
    }

    isFormOk = () => {
        this.validateForm();
        return Object.keys(this.validateForm()).length === 0;
    }

    isTouched = (name) => {
        return this.getStateKeyValue("touched", name);
    }

    handleFormValidation = ( values = {}) => {
        let errors = {};

        const { required = [] } = this.validate; 

        for(var r in required) {
            const name = required[r];
            if((!values[name] || values[name] === "") && this.isTouched(name)) errors[name] = "This field is required";
        }
    
        return errors;
    }

    validateForm = () => { 
        const errors = this.handleFormValidation(this.state.values)
        this.setState({ "errors" : errors});
        return errors;
    }

    setSubmitting = (state = true) => {
        this.setState({ "isSubmitting" : state});
    }

    setStateKey = (statekey, key, value) => {
        let k = this.state[statekey];
        k[key] = value;

        let params = {}; params[statekey] = k;
        this.setState(params)
    }

    setFieldValue = (field, value) => {
        this.setStateKey("values", field, value);
    }

    setAllFieldsToTouched = () => {
        const keys = this.getFields(); let params = {};
        for(var key in keys) params[keys[key]] = "true";
        this.setState({"touched" : params});
    }

    getStateKeyValue = (statekey, key) => {
        return (this.state[statekey] && this.state[statekey][key]) ? this.state[statekey][key] : "";
    }

    getError = (name) => {
        const { errors } = this.state;
        return errors && errors[name] ? errors[name] : "";
    }

    getFields = () => {
        return Object.keys(this.state.values);
    }
}