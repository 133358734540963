import axios from "axios";
import API from "../../components/API";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "/sessions/user";

export function login(email, password) {
    return new Promise((resolve, reject) => {
        API.Post({
            urlPath: "/users/login",
            reqBody: {
                email: email, 
                password: password
            }
        }, (err, data) => {
            if(err) reject(err);
            else resolve(data);
        })
    })
}

export function register(email, fullname, username, password) {
    return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
    return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
    return new Promise((resolve, reject) => {
        API.Get(ME_URL, (err, data) => {
            if(err) reject(err);
            else resolve(data);
        })
    });
}
