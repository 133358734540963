import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import Pages from "./Pages";
import RootPage from "./RootPage";
import Insights from "./Insights";
import FormResponses from "./FormResponses";

export default function HomePage({Layout}) {
  return (
    <Switch>
      {
        /* Redirect from root URL to /dashboard. */
        <Redirect exact from="/" to="/dashboard" />
      }
      
      <Route path="/dashboard">
          <Layout title={`Dashboard`} subheaderOptions={{title : ""}}>
            <Dashboard/>
          </Layout>
      </Route>

      <Route path="/pages/root/:slug">
        <RootPage Layout={Layout}/>
      </Route>

      <Route path="/pages">
        <Pages Layout={Layout}/>
      </Route>

      <Route path="/insights">
        <Insights Layout={Layout}/>
      </Route>

      <Route path="/form-responses">
        <FormResponses Layout={Layout}/>
      </Route>

      <Redirect to="/error/error-v1" />
    </Switch>
  );
}
