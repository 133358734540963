import React from "react";

export default function Page({Layout}) {
    return <PageComponent Layout={Layout}/>
}   

class PageComponent extends React.Component {
    render() {
        const { Layout } = this.props;

        return <Layout title = "Form Responses"></Layout>
    }
}
