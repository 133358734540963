import React from "react";
import { Spinner, EmptyComponent, PlaceholderComponent, AutoGrid } from "./Base";
import { Button } from "react-bootstrap";
import { Capitalize } from "./Util";

export const ErrorMessageHandler = ({value, message}) => (value !== null ? <div className="invalid-feedback">{message}</div> : <></>);

export const FormGroupInput = (props) => {
    const { FGLabel = "", touched = false, errors = {}, values = {}, name, onChange, type = "text"} = props;
    let { FGPlaceholder } = props;

    if(!FGPlaceholder) FGPlaceholder = "Enter " + FGLabel;

    const isTouched = touched, isInvalid = errors[name], inputValue = values[name];
    
    return <div className={`form-group ${isTouched ? "validated" : ""}`}>
        <label>{FGLabel}</label>
        <input type={type} className={`form-control ${isInvalid ? "is-invalid" : ""}`} placeholder={FGPlaceholder} name={name} value={inputValue} onChange={onChange}/>
        <ErrorMessageHandler value={inputValue} message={isInvalid}/>
    </div>
}

export const FormGroupSelect = (props) => {
    const { FGLabel = "", touched = {}, errors = {}, values = {}, name, onChange, options = []} = props;
    const isTouched = touched[name], isInvalid = errors[name], selectValue = values[name];

    return <div className={`form-group ${isTouched ? "validated" : ""}`} >
        <label>{FGLabel}</label>
        <select className="form-control" value={selectValue} onChange={onChange} name={name}>
            {
                options.map(({text, value}, index) => (<option value={value} key={`fgs-${index}`}>{text}</option>))
            }
        </select>
    </div>

}

export const SubmitButton = ({ isSubmitting, handleSubmit, text, afterSubmitMessage}) => {
    return (<><Button variant="brand" onClick={handleSubmit} disabled={isSubmitting} type="submit">
        {isSubmitting ? <Spinner sm={true}/>: <></>} 
        {isSubmitting ? <> Submitting </> : <> {text} </>}
    </Button>{afterSubmitMessage && <div className="kt-margin-l-10" style={{display: 'inline'}}>{afterSubmitMessage}</div>}</>)
}

export function FormValidator (values, { required }) {
    let errors = {};

    for(var r in required) {
        const name = required[r];
        if(!values[name] || values[name] === "") errors[name] = "This field is required";
    }

    return errors;
}


export function GenerateSelectOptions(fields, opts = {}) {
    const { capitalize = false } = opts;

    let options = [];

    for(var i=0; i<fields.length; i++) {
        let text = fields[i];
        let value = fields[i];
        

        if(capitalize){
            text = Capitalize(text);
        }

        options.push({ "text" : text, "value" : value});
    }

    return options;

}

export const FormInput = (props) => {
    const { label, type = "text", error = "", helper} = props, hasError = error !== "";
    let divClassList = ["form-group", "validated"], inputClassList = ["form-control"];

    if(hasError) inputClassList.push("is-invalid");

    return <div className={divClassList.join(" ")}>
        <label>{label}</label>
        <input type={type} className={inputClassList.join(" ")} {...props}/>
        { hasError ? <div className="invalid-feedback">{error}</div> : <></> }
        { helper && <span className="form-text text-muted">{helper}</span>}
    </div>
}

export const FormSelect = (props) => {
    const { label, error = "", options = [], helper } = props, hasError = error !== "";
    let divClassList = ["form-group", "validated"], inputClassList = ["form-control"];

    if(hasError) inputClassList.push("is-invalid");

    return <div className={divClassList.join(" ")}>
        <label>{label}</label>
        <select className="form-control" {...props}>
            {
                options.map(({text, value}, index) => (<option value={value} key={`fgs-${index}`}>{text}</option>))
            }
        </select>
        { helper && <span className="form-text text-muted">{helper}</span>}
    </div>
}

export const FormTextArea = (props) => {
    const { label, error = "", helper} = props, hasError = error !== "";

    let divClassList = ["form-group", "validated"], inputClassList = ["form-control"];
    if(hasError) inputClassList.push("is-invalid");

    return <div className={divClassList.join(" ")}>
        <label>{label}</label>
        <textarea className={inputClassList.join(" ")} {...props}></textarea>
        { helper && <span className="form-text text-muted">{helper}</span>}
    </div>
}

const FormBuilderItem = (props) => {
    const { type = "input" } = props; let component = <EmptyComponent/>;

    switch(type) {
        case "select" : 
            component = <FormSelect {...props}/>; break;
        case "textarea" : 
            component = <FormTextArea {...props}/>; break;
        default: 
            component = <FormInput {...props}/>; break;
    }

    return component;
}

const FormSectionLabel = ({ label }) => (<div className="form-group"><label>{label}</label></div>)

export class FormBuilder extends React.Component {
    render () {
        const { items, onChange, onBlur } = this.props; 

        return items ? items.map((item, index) => {
            const { type = "input" } = item; let actions = {};

            if(onChange && !item.onChange) actions.onChange = onChange;
            if(onBlur && !item.onBlur) actions.onBlur = onBlur;

            let component = <EmptyComponent/>;

            switch(type){
                case "submit" : 
                    component = <SubmitButton {...item}/>; break;
                case "section" : 
                    component = <FormSectionLabel {...item}/>; break;
                case "row": 
                    component = <AutoGrid col={item.items.length}>{
                        item.items.map((field, index) => {
                            return <FormBuilderItem key={`row-map-${index}`} {...field} {...actions}/>
                        })
                    }</AutoGrid>
                      
                    break;
                default:
                    component = <FormBuilderItem {...item} {...actions}/>; break;
            }

            return <PlaceholderComponent key={`form-builder-item-${index}`}> 
                {component}
            </PlaceholderComponent>
        }) : <EmptyComponent/>
    }
}