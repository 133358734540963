import axios from "axios"
import Config from "../Config";

import store from "../app/store/store";

const https = require('https');
 
function urlBuilder(url){
    const {port} = url;
    let fullUrl = url.url;

    if(port){
        fullUrl = fullUrl + ":" + port;
    }

    return fullUrl;
}

const APIInstance = axios.create({
    baseURL: urlBuilder(Config.api),
    timeout: 5000,
    headers: {
        "Content-Type" : "application/json"
    },
    httpsAgent: new https.Agent({  
        rejectUnauthorized: false
    })
});

function setAuthorization () {
    const {  auth: { authToken }} = store.getState();
    APIInstance.defaults.headers.common['Authorization'] = authToken;
}

async function Get(urlPath = "", callback){
    setAuthorization();

    APIInstance({ url: urlPath })
    .then(result =>{
        // debugger;
        return callback(null, result);
    })
    .catch(err => {
        // debugger;
        return callback(err);
    });
}

function Post({urlPath = "", reqBody}, callback){
    APIInstance({
        method: "post",
        url: urlPath,
        data: reqBody
    })
    .then(result =>{
        return callback(null, result);
    })
    .catch(err => {
        return callback(err);
    });
}

function Put({urlPath = "", reqBody}, callback){
    APIInstance({
        method: "put",
        url: urlPath,
        data: reqBody
    })
    .then(result =>{
        return callback(null, result);
    })
    .catch(err => {
        return callback(err);
    });
}


const API = {
    Get: Get,
    Post: Post,
    Put: Put,
    APIInstance: APIInstance
};

export default API;
