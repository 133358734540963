import React from "react";
import ProSourceForm from "../../../../components/ProSourceForm";
import { FormBuilder, SubmitButton, GenerateSelectOptions } from "../../../../components/Form";
import { Form } from "react-bootstrap";
import { Slugify } from "../../../../components/Util";
import API from "../../../../components/API";

export default class DetailsTab extends ProSourceForm {
    constructor (props) {
        super (props)

        const { data } = props;

        this.state = {
            page_id: data._id,
            afterSubmitMessage: "",
            values: {
                "title" : data.title,
                "slug": data.slug,
                "type" : data.type,
                "status" : data.status,
                "helmet_lines" : data.helmet_lines.join("\n")
            },
            errors: {},
            touched: {},
            isSubmitting: false
        };

        this.validate = {
            required: ["title", "slug"]
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = (event) => {
        const target = event.target, value = target.value, name = target.name;
        if(name === "title"){
            this.setFieldValue("slug", Slugify(value));
            this.validateForm();
        }
    }

    onSubmit = (values) => {
        values.helmet_lines = values.helmet_lines.split("\n");

        API.Put({
            urlPath: `/pages/${this.state.page_id}`,
            reqBody : values
        }, (err, data) => {
            this.setSubmitting(false);
            if(err) console.log(err);
            else {
                const newValues = data.data;

                this.setState({
                    "values" : {
                        title : newValues.title,
                        slug : newValues.slug,
                        type : newValues.type,
                        status : newValues.status,
                        helmet_lines : newValues.helmet_lines.join("\n")
                    },
                    "afterSubmitMessage" : <span className="kt-font-success">Changes saved!</span>
                })
               console.log(data);
            }
        });
    }

    render () {
        
        const { isSubmitting, afterSubmitMessage } = this.state, { title, slug, type, status, helmet_lines} = this.state.values;

        return <Form onSubmit={this.handleOnSubmit}>
            <FormBuilder onBlur={this.handleOnBlur} onChange={this.handleOnChange} items={[
                {
                    type: "input",
                    name : "title",
                    value : title,
                    label : "Page Title",
                    placeholder : "Enter Page Title",
                    error : this.getError('title')
                },
                {
                    type: "input",
                    name : "slug",
                    value : slug,
                    label : "Page Slug",
                    placeholder : "Enter Page Slug",
                    error : this.getError('slug')
                },
                {
                    type: "row",
                    items : [
                        {
                            type: "select",
                            name : "type",
                            label: "Page Type",
                            options: GenerateSelectOptions(["root", "expertise", "insight"], { capitalize : true}),
                            value: type,
                            error : this.getError('type')
                        },
                        {
                            type: "select",
                            name : "status",
                            label: "Page Status",
                            options: GenerateSelectOptions(["draft", "published", "hidden"], { capitalize : true}),
                            value: status,
                            error : this.getError('status')
                        }
                    ]
                },
                {
                    type: "textarea",
                    name: "helmet_lines",
                    label: "Meta Tags",
                    placeholder: `Eg. <meta property="og:type" content="article">\n<meta property="og:description" content="Description goes here.">`,
                    value: helmet_lines,
                    helper: "Please separate each entry with a new line as shown on the placeholder above.",
                    error: this.getError('helmet_lines')
                },
                {
                    type: "submit",
                    isSubmitting : isSubmitting,
                    handleSubmit: this.handleOnSubmit,
                    afterSubmitMessage: afterSubmitMessage,
                    text: "Update Page"
                }
            ]}/>
        </Form>
    }
}