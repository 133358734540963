import React from "react";

import { Modal, Button } from "react-bootstrap";
import { SubmitButton, FormValidator, FormInput } from "../../../components/Form";

import { Slugify } from "../../../components/Util";

const initialValues = {
    "title" : "", 
    "slug" : ""
};

const validate = values => {
    let errors = {};

    errors = FormValidator(values, {
        "required" : ["title", "slug"]
    });

    return errors;
};

export default class QuickAddPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            form: initialValues,
            errors: {},
            isSubmitting: false
        }

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);

        this.setSubmitting = this.setSubmitting.bind(this);

        this.validateForm = this.validateForm.bind(this);

        this.getError = this.getError.bind(this);

        this.isFormOk = this.isFormOk.bind(this);
    }

    isFormOk = () => {
        this.validateForm(this.state.form);
        return Object.keys(this.state.errors).length === 0;
    }

    setSubmitting = (state = true) => {
        this.setState({ "isSubmitting" : state});
    }

    validateForm = ( values = {}) => {
        this.setState({ "errors" : validate(values)});
    }

    getError = (name) => {
        const { errors } = this.state;
        return errors && errors[name] ? errors[name] : "";
    }

    handleOnChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        let { form } = this.state;

        form[name] = value;

        if(name === "title"){
            form["slug"] = Slugify(value);
        }

        this.setState({
            form: form
        });

        this.validateForm(form);
    }

    handleOnSubmit = event => {
        event.preventDefault();

        if(this.isFormOk()){
            this.setSubmitting();
            this.props.onSubmit(this.state.form, this.setSubmitting);
        }
    }

    render () {
        const { onHide } = this.props, { isSubmitting } = this.state, { title, slug } = this.state.form;

        return <form onSubmit={this.handleOnSubmit}>
            <Modal.Body>
                <FormInput name="title" value={title} label={"Page Title"}  placeholder={"Enter Page Title"} onChange={this.handleOnChange} error={this.getError("title")}/>
                <FormInput name="slug" value={slug} label={"Page Slug"}  placeholder={"Enter Page Slug"} onChange={this.handleOnChange} error={this.getError("slug")}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <SubmitButton isSubmitting={isSubmitting} handleSubmit={this.handleOnSubmit} text="Add Page"/>
            </Modal.Footer>
        </form>
    }
}