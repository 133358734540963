import React, { useMemo } from "react";
import { Link } from "react-router-dom"
import { useSelector } from "react-redux";

import {
  Portlet,
  PortletBody
} from "../../partials/content/Portlet";

const DashboardSquare = ({number, description}) => <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
    <PortletBody fluid={true}>
        <div className="kt-widget26">
            <div className="kt-widget26__content">
                <span className="kt-widget26__number">{number}</span>
                <span className="kt-widget26__desc">{description}</span>
            </div>
            <div className="kt-widget26__chart" style={{ width: "230px", height: "100px" }}></div>
        </div>
    </PortletBody>
</Portlet>

const WelcomeDiv = ({title = "", description = ""}) =>  <div className="kt-portlet kt-callout kt-callout--brand kt-callout--diagonal-bg">
    <div className="kt-portlet__body">
        <div className="kt-callout__body">
            <div className="kt-callout__content">
                <h3 className="kt-callout__title">{title}</h3>
                <p className="kt-callout__desc">
                    {description}
                </p>
            </div>
            <div className="kt-callout__action">
                <a href="#" data-toggle="modal" data-target="#kt_chat_modal" className="btn btn-custom btn-bold btn-upper btn-font-sm  btn-brand">Do Something</a>
            </div>
        </div>
    </div>
</div>

const IconBox = ({link, icon, title, content}) => (
    <Link to={link} className="kt-portlet kt-iconbox kt-iconbox--animate-slow">
        <div className="kt-portlet__body">
            <div className="kt-iconbox__body">
                <div className="kt-iconbox__icon">{icon}</div>
                <div className="kt-iconbox__desc">
                    <h3 className="kt-iconbox__title">{title}</h3>
                    <div className="kt-iconbox__content">{content}</div>
                </div>
            </div>
        </div>
    </Link>
)

const Greeting = () => {
    const hour = (new Date()).getHours();
    
    if(hour < 12) {
        return "Good morning";
    }
    else if(hour < 18){
        return "Good afternoon";
    }
    else {
        return "Good evening";
    }
}

export default function Dashboard() {
  return (
    <>
        <div className="row">
            <div className="col-lg-12">
                <WelcomeDiv title={`${Greeting()}, Jessiree.`} description={`Welcome back, here's what you've missed.`}/>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-4">
                <IconBox 
                    link="pages"
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24"/>
                            <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fillRule="nonzero"/>
                            <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"/>
                        </g>
                    </svg>}
                    title="Pages"
                    content="Manage your pages"/>
            </div>
            <div className="col-lg-4">
                <IconBox 
                    link="insights"
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M15.9956071,6 L9,6 C7.34314575,6 6,7.34314575 6,9 L6,15.9956071 C4.70185442,15.9316381 4,15.1706419 4,13.8181818 L4,6.18181818 C4,4.76751186 4.76751186,4 6.18181818,4 L13.8181818,4 C15.1706419,4 15.9316381,4.70185442 15.9956071,6 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
                            <path d="M10.1818182,8 L17.8181818,8 C19.2324881,8 20,8.76751186 20,10.1818182 L20,17.8181818 C20,19.2324881 19.2324881,20 17.8181818,20 L10.1818182,20 C8.76751186,20 8,19.2324881 8,17.8181818 L8,10.1818182 C8,8.76751186 8.76751186,8 10.1818182,8 Z" fill="#000000"/>
                        </g>
                    </svg>}
                    title="Insights"
                    content="Navigate through your insights"/>
            </div>
            <div className="col-lg-4">
                <IconBox 
                    link="form-responses"
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                            <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                            <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>
                            <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>
                        </g>
                    </svg>}
                    title="Form Responses"
                    content="See responses from contact form"/>
            </div>
        </div>
    </>
  );
}
